/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import './src/styles/css/bootstrap.min.css'
import 'animate.css'
import "./src/styles/css/boxicons.min.css"
import "./src/styles/css/flaticon.css"
import 'react-accessible-accordion/dist/fancy-example.css'
import "/node_modules/aos/dist/aos.css"
import "swiper/css"
import "swiper/css/bundle"
import "./src/styles/css/slick.css"

// Global style
import "./src/styles/css/style.css" 
import "./src/styles/css/responsive.css" 
import mixpanel from "mixpanel-browser";

// If you want Multicolor comment out single
// import './src/styles/css/colors/brink-pink-style.css'
// import './src/styles/css/colors/pink-style.css'
// import './src/styles/css/colors/purple-style.css'

export const onClientEntry = () => {
    console.log("We've started!")
    mixpanel.init("440b4db59343d41c89c8f50b37f0e43a", {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
  }